<template>
  <b-modal
      id="product-brand-edit-modal"
      ref="product-brand-edit-modal"
      cancel-variant="outline-secondary"
      :ok-title="okTitle"
      @ok="handleOk"
      cancel-title="Kapat"
      centered
      :title="title"
      @hidden="hidden"
  >
    <b-form-group
        label-cols-sm="4"
        content-cols-sm
        label="Marka No : "
    >
      <b-form-input :value="_editBrand.id" disabled/>
    </b-form-group>

    <b-form-group
        label-cols-sm="4"
        content-cols-sm
        label="Marka Adı : "
        label-for="input-horizontal"
        :description="labelDesc"
    >
      <b-form-input v-model="_editBrand.name"/>
    </b-form-group>
    <hr>
    {{ message }}
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ProductBrandEdit",
  data: () => ({
    message: ""
  }),
  computed: {
    ...mapGetters("productBrand", ["_editBrand"]),
    labelDesc() {
      if (this._editBrand.id) return "Marka adı düzenle."
      return "Marka adı oluştur."
    },
    title() {
      if (this._editBrand.id) return "Marka Düzenle"
      return "Marka Oluştur"
    },
    okTitle() {
      if (this._editBrand.id) return "Güncelle"
      return "Kaydet"
    }
  },
  methods: {
    handleOk(bvModalEvent) {
      this.message = ""
      bvModalEvent.preventDefault()
      if (this._editBrand.id) {
        this.$store.dispatch('productBrand/updateBrand', this._editBrand)
            .then(res => {
              this.message = res.message;
            })
      } else {
        this.$store.dispatch('productBrand/storeBrand', this._editBrand)
            .then(res => {
              this.message = res.message;
            })
      }
    },
    hidden() {
      this.$store.commit('productBrand/setEditBrand', {})
      this.message = ""
    }
  },

}
</script>

<style scoped>

</style>
