<template>
  <div class="m-0 p-0">
    <span v-html="name" class="m-0 p-0"/>
    <span class="m-0 p-0"  :class="variant">{{
        new Date(date).toLocaleString("tr-TR", {
          dateStyle: "short",
          timeStyle: "short",
        })
      }}
    </span>
  </div>
</template>

<script>
export default {
  name: "ViewDate",
  props: {
    variant: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    },
    date: {
      type: String,
      required: true
    }
  }
}
</script>
