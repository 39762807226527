<template>
  <section>
    <ProductBrandSearch/>


    <div
        v-for="(fihrist,index) in _fihrist"
        :key="index"
    >
      <b-row>
        <b-col>
          <b-card>
            <b-row class="border shadow-sm rounded bg-primary">
              <b-col style="padding-top: 10px">
                <h4 class=" text-white"> {{ fihrist.firstChar }}</h4>
              </b-col>
            </b-row>
            <b-row class="mt-1 pt-1 pb-1 border shadow-sm rounded">
              <b-col
                  v-for="brand in fihrist.brands"
                  :key="brand.id"
                  cols="3"
                  style="padding-bottom: 6px"
              >
                <div v-if="brand.stores.length>0">
                  <b-form-checkbox
                      @change="seleceted($event,brand.id)"
                      :checked="brand.id===store.pivot.brand_id && store.pivot.store_id=== _search.storeId"
                      v-for="store in brand.stores"
                      :key="store.id"
                      style="margin-left: 3px"
                  >{{ brand.name }}
                  </b-form-checkbox>
                </div>
                <div v-else>
                  <b-form-checkbox
                      @change="seleceted($event,brand.id)"
                  >
                    {{ brand.name }}
                  </b-form-checkbox>
                </div>


              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <ProductBrandEdit/>
  </section>
</template>

<script>
import ProductBrandSearch from "@/views/product/brand/ProductBrandSearch";
import {mapGetters} from "vuex";
import Vue2Filters from "vue2-filters";
import ProductBrandEdit from "@/views/product/brand/ProductBrandEdit";
import viewDate from "@/views/common/ViewDate";
import axios from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "ProductBrandHome",
  data: () => ({
    fields: [
      'id',
      {key: 'name', label: 'Name'},
      {key: 'status', label: 'Durum'},
      {key: 'date', label: 'Tarih'},
      {key: 'setup', label: 'Yönetim'}
    ],
  }),
  components: {ProductBrandSearch, ProductBrandEdit, viewDate},
  mixins: [Vue2Filters.mixin],
  computed: {
    ...mapGetters('productBrand', ["_brands", "_fihrist", "_search"]),
    filterBrands() {
      let filter = this._brands.filter(item => item.name.toLowerCase().match(this.search.toLowerCase()));
      return this.limitBy(filter, 20)
    }
  },
  methods: {
    seleceted(evt, id) {
      if (evt) {
        axios.post(`/store/${this._search.storeId}/brand/${id}`)
            .then(res => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: '',
                  variant: 'success',
                  title: 'Başarılı',
                  text: item.name + ' başarıyla eklendi.'
                }
              });
            })
      } else {
        axios.delete(`/store/${this._search.storeId}/brand/${id}`)
            .then(res => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: '',
                  variant: 'danger',
                  title: 'Başarılı',
                  text: this.selectedItem.name + ' başarıyla silindi'
                }
              });
            })
      }
    },
    deleteBrand(id) {
      let text = "Silmek istediğinizden emin misiniz?";
      if (confirm(text) == true) {
        this.$store.dispatch('productBrand/deleteBrand', id)
            .then(res => {
              if (res.status != 'success') {
                alert('Sime Başarısız');
              }
            })
            .catch(() => {
              alert('Silme Hatası');
            })
      }
    },
    editBrand(item) {
      this.$store.commit('productBrand/setEditBrand', item);
      this.$root.$emit('bv::show::modal', 'product-brand-edit-modal', '#btnShow')
    }
  }

}
</script>

<style scoped>

</style>
