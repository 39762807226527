<template>
  <b-overlay :show="show" rounded="lg">
    <b-card>

      <b-form @submit.prevent>
        <b-row>
          <b-col>
            <b-row>
              <b-col cols="2">
                <v-select
                    v-model="_search.storeId"
                    label="store_name"
                    :options="_stores"
                    :reduce="store => store.id"
                    class="select-size-sm"
                    placeholder="Pazaryeri"
                    :closeOnSelect="false"
                />
              </b-col>
              <b-col cols="2">

              </b-col>
            </b-row>
          </b-col>
          <b-col md="2">
            <b-button-group size="sm">
              <b-button
                  :disabled="!_search.storeId"
                  variant="primary"
                  type="submit"
                  @click="getAll(0)"
              >
                <feather-icon icon="FilterIcon"></feather-icon>
              </b-button>
              <b-button variant="outline-success" @click="addNewBrand">
                <feather-icon icon="PlusCircleIcon"></feather-icon>
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-form>

    </b-card>
  </b-overlay>
</template>

<script>
import vSelect from "vue-select";
import {mapGetters} from "vuex";

export default {
  name: "ProductBrandSearch",
  components: {vSelect},
  data: () => ({
    show: false,
  }),
  computed: {
    ...mapGetters('store', ["_stores"]),
    ...mapGetters('productBrand', ["_search"])
  },
  methods: {
    getAll(page) {
      this.show = true;
      this.$store.commit('productBrand/setFihrist', [])
      this.$store.dispatch('productBrand/getAll')
          .then(res => {
            if (res) {
              this.show = false;
            }
          })
    },
    addNewBrand() {
      this.$store.commit('productBrand/setEditBrand', {"id": null, "name": "", "status": 1});
      this.$root.$emit('bv::show::modal', 'product-brand-edit-modal', '#btnShow')
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
